
<template>
  <div v-if="userId != null" class="chat__header">
    <vs-navbar class="p-3 flex navbar-custom" color="white" type="flat">
      <div class="relative flex mr-4">
        <feather-icon icon="MenuIcon" class="mr-4 cursor-pointer" v-if="isSidebarCollapsed" @click.stop="$emit('openContactsSidebar')" />
        <vs-avatar class="m-0 border-2 border-solid border-white" size="36px" :src="absoluteUrl(userDetails.image)" @click.stop="$emit('showProfileSidebar', userId)" />
        <div class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0" :class="'bg-' + getStatusColor"></div>
      </div>
      <h6>{{ userDetails.first_name }} {{ userDetails.last_name }}</h6>
      <vs-spacer></vs-spacer>
      <feather-icon icon="StarIcon" class="cursor-pointer" :svgClasses="[{'text-warning stroke-current': isPinnedLocal}, 'w-6', 'h-6']" @click.stop="isPinnedLocal = !isPinnedLocal"></feather-icon>
    </vs-navbar>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },

    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
        isPinnedLocal: false,
    }
  },
  computed: {
    userDetails() {
      return this.$store.getters['chat/contact'](this.userId)
    },
    getStatusColor() {
      const userStatus = this.userDetails.chat_status;
      if (userStatus == "online") {
        return "success"
      } else if (userStatus == "do not disturb") {
        return "danger"
      } else if (userStatus == "away") {
        return "warning"
      } else {
        return "grey"
      }
    },
  },
}

</script>

