
<template>
  <div id="chat-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <vs-sidebar class="items-no-padding" parent="#chat-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isChatSidebarActive" id="chat-list-sidebar">
        <!-- USER PROFILE SIDEBAR -->
      <user-profile :active="activeProfileSidebar" :userId="userProfileId" class="user-profile-container" :isLoggedInUser="isLoggedInUserProfileView" @closeProfileSidebar="closeProfileSidebar"></user-profile>

      <div class="chat__profile-search flex p-3">
        <div class="relative inline-flex">
          <vs-avatar v-if="activeUser.image" class="m-0 border-2 border-solid border-white" :src="absoluteUrl(activeUser.image)" size="36px" @click="showProfileSidebar(Number(activeUser.id), true)" />
          <div class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0" :class="'bg-' + getStatusColor(true)"></div>
        </div>
        <vs-input icon-no-border icon="icon-search" icon-pack="feather" id="chat-contact-search" class="w-full mx-5 input-rounded-full" placeholder="Search or start a new chat" v-model="searchQuery"/>

        <feather-icon class="md:inline-flex lg:hidden -ml-3 cursor-pointer" icon="XIcon" @click="toggleChatSidebar(false)" />
      </div>

      <vs-divider class="d-theme-border-grey-light m-0" />
      <VuePerfectScrollbar class="chat-scroll-area pt-4" :settings="settings" :key="$vs.rtl">
        <!-- CONTACTS LIST -->
        <div class="chat__contacts">
          <h6 class=" text-primary mb-4 px-4">Contacts</h6>
          <ul class="chat__contacts bordered-items">
              <li class="cursor-pointer" v-for="(contact, cotIndex) in contacts" :key="cotIndex" @click="updateActiveChatUser(contact.id)">
                  <chat-contact :contact="contact" :lastMessaged="chatLastMessaged(contact.id) ? chatLastMessaged(contact.id).created_at : ''" :unseenMsg="chatUnseenMessages(contact.id)" :isActiveChatUser="isActiveChatUser(contact.id)"></chat-contact>
              </li>
          </ul>
        </div>
      </VuePerfectScrollbar>
    </vs-sidebar>

    <!-- RIGHT COLUMN -->
    <div :style="{'background-image': `url(${absoluteUrl()}/images/bg/bg-chat-tile.png)`}" class="chat__bg no-scroll-content chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0" :class="{'sidebar-spacer--wide': clickNotClose, 'flex items-center justify-center': activeChatUser === null}">
        <template v-if="activeChatUser">
            <div class="chat__navbar">
                <chat-navbar :isSidebarCollapsed="!clickNotClose" :user-id="activeChatUser" @openContactsSidebar="toggleChatSidebar(true)" @showProfileSidebar="showProfileSidebar"></chat-navbar>
            </div>
            <VuePerfectScrollbar class="chat-content-scroll-area border border-solid d-theme-border-grey-light" :settings="settings" ref="chatLogPS" :key="$vs.rtl">
                <div class="chat__log" ref="chatLog">
                    <chat-log :userId="activeChatUser" v-if="activeChatUser"></chat-log>
                </div>
            </VuePerfectScrollbar>
            <div class="chat__input flex items-baseline p-2 bg-white">
                <vs-button radius color="dark" @click.prevent="popupAttachment = true"  class="inline mr-2" type="flat" icon-pack="feather" icon="icon-paperclip"></vs-button>
                <vs-input class="flex-1" placeholder="Type Your Message" v-model="typedMessage" @keyup.enter="sendMsg" />
                <vs-button class="bg-primary-gradient ml-2 flex items-center" type="filled" @click="sendMsg"> <feather-icon v-if="isSmallerScreen" icon="SendIcon" class="" svgClasses="pt-1 w-4 h-4"> </feather-icon> {{isSmallerScreen ? '': 'Send'}}</vs-button>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col items-center">
                <feather-icon icon="MessageSquareIcon" class="mb-4 bg-white p-5 shadow-md rounded-full" svgClasses="w-10 h-10"></feather-icon>
                <h5 class=" py-2 px-4 bg-white shadow-md rounded-full cursor-pointer" @click.stop="toggleChatSidebar(true)">Start Conversation</h5>
            </div>
        </template>
    </div>
    <vs-popup
        @close="initializeAll"
        title="Add Files"
        :active.sync="popupAttachment">
        <div>
          <input type="file" class="hidden" multiple ref="fileUploadAttached" @change="fileInputReader" accept="zip/*">
          <vs-button size="small" @click="$refs.fileUploadAttached.click()">Choose files</vs-button>
          <div class="mt-2">
              <vs-list>
                <vs-list-item v-for="(file, index) in attachmentNames" :key="index">
                <template slot="avatar">
                <div>
                  <span class="font-semibold">{{index+1}}.)  {{file.name}} (<span class="text-success">size: {{file.size | k_formatter}}b </span>)</span>
                </div>
                </template>
              </vs-list-item>
            </vs-list>
          </div>
          <vs-divider/>
          <div class="flex flex-wrap justify-end items-center mt-5">
            <vs-button type="filled" @click.prevent="sendAttachment" :disabled="!attachedFiles.length" class="mr-6">Send</vs-button>
          </div>
        </div>
    </vs-popup>
  </div>
</template>

<script>

import ChatContact         from "./ChatContact.vue"
import ChatLog             from './ChatLog.vue'
import ChatNavbar          from './ChatNavbar.vue'
import UserProfile         from "./UserProfile.vue"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
    ChatContact,
    UserProfile,
    ChatNavbar,
    ChatLog,
  },
  data() {
    return {
      active               : true,
      isHidden             : false,
      searchContact        : "",
      activeProfileSidebar : false,
      activeChatUser       : null,
      userProfileId        : -1,
      typedMessage         : "",
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70,
      },
      clickNotClose        : true,
      isChatSidebarActive  : true,
      isLoggedInUserProfileView: false,
      attachmentNames: [],
      attachedFiles: [],
      popupAttachment: false,
    }
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth()
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    chatLastMessaged() {
      return (userId) => this.$store.getters['chat/chatLastMessaged'](userId)
    },
    chatUnseenMessages() {
      return (userId) => {
        const unseenMsg = this.$store.getters['chat/chatUnseenMessages'](userId)
        if (unseenMsg) return unseenMsg
      }
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    },
    getStatusColor() {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser)
        if (userStatus == "online") {
          return "success"
        } else if (userStatus == "do not disturb") {
          return "danger"
        } else if (userStatus == "away") {
          return "warning"
        } else {
          return "grey"
        }
      }
    },
    // chatContacts() {
    //   return this.$store.getters['chat/chatContacts']
    // },
    contacts() {
      return this.$store.getters['chat/contacts']
    },
    searchQuery: {
      get() {
        return this.$store.state.chat.chatSearchQuery
      },
      set(val) {
        this.$store.dispatch('chat/setChatSearchQuery', val)
      }
    },
    isActiveChatUser() {
      return (userId) => userId == this.activeChatUser
    },
    windowWidth() {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    getUserStatus(isActiveUser) {
      return (isActiveUser) ? this.activeUser.chat_status : this.contacts[this.activeChatUser].chat_status
    },
    closeProfileSidebar(value) {
      this.activeProfileSidebar = value
    },
    updateActiveChatUser(contactId) {
      this.activeChatUser = contactId
      if (this.$store.getters['chat/chatDataOfUser'](this.activeChatUser)) {
        this.$store.dispatch('chat/markSeenAllMessages', contactId)
        .then((response) => {
          if(response.data.success){
            this.$store.dispatch('recallChatCount')
          }
        })
      }
      let chatData = this.$store.getters['chat/chatDataOfUser'](this.activeChatUser)
      this.toggleChatSidebar()
      this.typedMessage = ''
    },
    showProfileSidebar(userId, openOnLeft = false) {
      this.userProfileId = userId
      this.isLoggedInUserProfileView = openOnLeft
      this.activeProfileSidebar = !this.activeProfileSidebar
    },
    sendMsg() {
      if ((this.typedMessage == '') || !this.activeChatUser || (this.activeChatUser === this.activeUser.id)){return}
      const payload = {
        'to'      : this.activeChatUser,
        'message' : this.typedMessage,
      }
      this.$store.dispatch('chat/sendChatMessage', payload)
      this.typedMessage = ''
      this.$refs.chatLogPS.$el.scrollTop = this.$refs.chatLog.scrollHeight
    },
    sendAttachment(){
      if(this.attachedFiles.length < 1){return}
       let formData = new FormData()
        this.attachedFiles.forEach((item, index) => formData.append('file_'+index, item))
        formData.append('to', this.activeChatUser)
        this.$vs.loading({color: "#222", type:"material"})
        this.$store.dispatch('chat/sendChatAttachments', formData)
        .then((response) => {
          this.$vs.loading.close()
          if(response.data.success){
            this.initializeAll()
          }
        })
    },
    fileInputReader(input) {
      this.attachmentNames = []
      this.attachedFiles = []
      if (input.target.files && input.target.files[0]) {
        for (let i = 0; i < input.target.files.length; i++) {
          this.attachmentNames.push({
            name: input.target.files[i].name,
            size: input.target.files[i].size
          })
          this.attachedFiles.push(input.target.files[i])
        }
      }
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false
      } else {
        this.isChatSidebarActive = this.clickNotClose = true
      }
    },
    toggleChatSidebar(value = false) {
      if (!value && this.clickNotClose) return
      this.isChatSidebarActive = value
    },
    initializeAll(){
      this.attachmentNames = []
      this.attachedFiles = []
      this.popupAttachment =  false
    },
  },
  created() {
    if(!this.$store.state.chat.chats.length){
      this.$store.dispatch('chat/fetchChats');
    }
    if(!this.contacts.length){
      this.$store.dispatch('chat/fetchContacts')
    }
    this.setSidebarWidth()
  },
  beforeDestroy: function() {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    document.querySelector('.layout--main').classList.toggle('footer-hidden')
  },
  mounted() {
    document.querySelector('.layout--main').classList.toggle('footer-hidden')
    this.$emit('updateFooter', 'hidden')
    this.$store.dispatch("chat/setChatSearchQuery", "")
    this.wasSidebarOpen = this.$store.state.reduceButton
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  }
}

</script>


<style lang="scss">
@import "@assets/scss/apps/chat.scss";
#chat-contact-search{
  padding: .5rem 1rem 0.8rem 3rem !important;
}
.no-scroll.navbar-floating .no-scroll-content{
  height: calc(var(--vh, 1vh) * 100 - 8.5rem);
}
.sidebar-spacer--wide {
    width: calc(100% - 350px);
}
[dir=ltr] .sidebar-spacer--wide {
    margin-left: 350px;
}
[dir] .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
    padding: .62rem 1rem;
}
</style>
